.background-image {
  display: flex;
  flex-direction: row;
}

.landing-button {
  // ...
}

.content-container {
  margin: 0 10%;
}

.background-image {
  position: relative;
}

.background-image::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.text-box {
  color: white;
  padding: 15px;
}
